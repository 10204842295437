/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
            <li>
              Switchboard - Reception<br />  <i className="now-ui-icons tech_mobile"></i>+39 040 3787 111
            </li>
            <li>
              Report errors or changes<br />
              <i className="now-ui-icons ui-1_email-85"></i><a href={"mailto:phonebook@sissa.it"}>phonebook@sissa.it</a>
            </li>
            <li>
              Copyright ©{new Date().getFullYear()} SISSA. <br /> Scuola Internazionale Superiore di Studi Avanzati
            </li>
          </ul>
        </nav>
        {/*<div className="copyright" id="copyright">*/}
        {/*  Copyright ©{new Date().getFullYear()} SISSA. <br /> Scuola Internazionale Superiore di Studi Avanzati*/}
        {/*</div>*/}
      </Container>
    </footer>
  );
}

export default DarkFooter;
