import React, {Component, useEffect} from "react";

// reactstrap components
import {
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Dot } from 'react-animated-dots';

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import SissaPersonCard from "./index-sections/SissaPersonCard";
import SissaStructureCard from "./index-sections/SissaStructureCard";
import removeAccents from "remove-accents";
// import {SissaPeople} from "../people";
// import {SissaStructures} from "../structures";
// import removeAccents from "remove-accents";
// import axios from 'axios';


function IndexEffect(){
  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
}

function IndexHeaderEffect(){
  let pageHeader = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
            "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
}

class Index extends Component {

  state = {
    // sissa_people_cards: SissaPeople,
    // sissa_structures_cards: SissaStructures,
    sissa_people_api_cards: [],
    sissa_api_structures_cards: [],
    client_ip: null,
    external_network: null,
    component_mounted: false,
    query: '',
    leftFocus: false,
    loading: true,
    rightFocus: false,
    disclaimer_users: []
  }

  componentDidMount() {
    fetch('https://idman.web-services.sissa.it/phonebook/api/people/all.json?key=x47fnfkds984nldsf')
        .then((response) => response.json())
        .then(people => {
          this.setState({ sissa_people_api_cards: people, component_mounted: true, loading: false });
        }).catch(function(){
          console.log("error");
        });

    fetch('https://idman.web-services.sissa.it/phonebook/api/structures/all.json?key=x47fnfkds984nldsf')
        .then((response) => response.json())
        .then(structures => {
          // this.setState({ sissa_api_structures_cards: structures, component_mounted: true, loading: false });
          this.setState({ sissa_api_structures_cards: structures });
        }).catch(function(){
          console.log("error");
        });

    fetch('https://idman.web-services.sissa.it/phonebook/api/client/network.json?key=x47fnfkds984nldsf')
        .then((response) => response.json())
        .then(response_data => {
          console.log(response_data);
          let IP = response_data[0].client_ip;
          let ext = response_data[0].external;
          this.setState({ external_network: ext, client_ip: IP });
        });
    
    fetch('/data/disclaimer_users.json')
        .then((response) => response.json())
        .then(disclaimer_data => {
          this.setState({ disclaimer_users: disclaimer_data });
        }).catch(function(){
          console.log("error");
        });
    // this.setState({ loading: true });
  }

  addDisclaimer(results) {
    results.forEach((element) => {
      if (this.state.disclaimer_users.includes(element.id)) {
        //console.log(element.full_name + " wants a disclaimer");
        element.disclaimer = true;
      };
    });
    return results;
  }

  fetchPerson(id){
    if(id===null)return;
    try{
      document.getElementById('input_search_group').style.display='none';
    }catch(e){
      console.log(e);
    }

    const search_keys = ["id"];
    let results = null;
    try{
      results = this.state.sissa_people_api_cards.filter(
          (person) => search_keys.some(
              search_key => (person[search_key]==id)
          )
      );
    }catch (error){
      let message = "An error occurred";
      console.log(message);
      console.log(error);
      return;
    }

    if(results.length>0){
      results = this.addDisclaimer(results);
      let people_cards = results.map(person => (
          <SissaPersonCard
              key={person.id}
              card={person}
              external_network={this.state.external_network}
          />
      ));
      return people_cards;
    }else return <Col>Nothing found</Col>
  }

  fetchStructure(id){
    try{
      document.getElementById('input_search_group').style.display='none';
    }catch(e){
      console.log(e);
    }

    const search_keys = ["id"]
    const mapping = function(structure, network){
      let display = ((network===true && structure.display_internal_only==='0') || network===false ? true : false);
      // console.log("display " + display);
      if(display===true){// esterno
        return <SissaStructureCard
            key={structure.id}
            card={structure}
        />
      }else return ''
    }
    let results = this.state.sissa_api_structures_cards.filter((
        structure) => search_keys.some(search_key=>structure[search_key]==id)).map(structure => mapping(structure, this.state.external_network));
    if(results.length>0){
      return results;
    }else return <Col>Nothing found</Col>
  }

  fetchPeople(){
    try{
      document.getElementById('input_search_group').style.display=null;
    }catch(e){
      console.log(e);
    }

    const search_keys = ["sissa_email","full_name","role_task","sissa_phone"]

    let removeAccents = require("remove-accents");
    if(this.state.query==null) this.state.query = '';
    let sanitized_query = removeAccents(this.state.query.toLowerCase().replaceAll(' ',''));
    if(sanitized_query.length>2){
      // console.log(removeAccents(this.state.query));
      // console.log(this.state.sissa_people_api_cards);
      // throw new Error("test");
      // return
      let results = null;
      try{
        results = this.state.sissa_people_api_cards.filter(
            (person) => search_keys.some(
                search_key => person[search_key] && removeAccents(person[search_key]).toLowerCase().includes(sanitized_query)
            )
        ).slice(0, 20);
      }catch(error){
        console.log(error);
        return <Col>Initialization error occurred - incident was reported</Col>
      }
      // console.log(results);
      let count = results.length
      if(results.length>0){
        results = this.addDisclaimer(results);
        if(results.length===20){
          document.getElementById('res_message').style.display = 'block';
        }else{
          document.getElementById('res_message').style.display = 'none';
        }

        let people_cards = results.map(person => (
            <SissaPersonCard
                key={person.id}
                card={person}
                external_network={this.state.external_network}
            />
        ));
        return people_cards;
      }else return <Col>Nothing found</Col>


    }else{
      if(sanitized_query!=='')
        return <Col>Please insert more than 2 characters</Col>
    }
  }

  fetchStructures(){
    try{
      document.getElementById('input_search_group').style.display=null;
    }catch(e){
      console.log(e);
    }

    const search_keys = ["description_en","description_it","email","acronym","sissa_phone"]
    let removeAccents = require("remove-accents");
    if(this.state.query==null) this.state.query = '';
    let sanitized_query = removeAccents(this.state.query.toLowerCase().replaceAll(' ',''));

    const mapping = function(structure, network){
      let display = ((network===true && structure.display_internal_only==='0') || network===false ? true : false);
      // console.log("display " + display);
      if(display===true){// esterno
        return <SissaStructureCard
            key={structure.id}
            card={structure}
        />
      }else return ''

    }
    if(sanitized_query.length>2){
      let results = null;
      try{
        console.log(sanitized_query);
        results = this.state.sissa_api_structures_cards.filter((
            structure) => search_keys.some(search_key=>structure[search_key] && structure[search_key].toLowerCase().includes(sanitized_query))).map(structure => mapping(structure, this.state.external_network))

        if(results.length>0) return results;
        else return <Col>Nothing found</Col>
      }catch(error){
        console.log(error);
        return <Col>Initialization error occurred - incident was reported</Col>
      }
    }else{
      if(sanitized_query.length>0) return <Col>Please insert more than 2 characters</Col>
    }
  }

  fetchResults(){
    let hash = document.location.hash;
    console.log(document.location);
    let pathname = document.location.pathname;
    let path_array = pathname.split("/");
    console.log(path_array);
    let path = '';
    if(path_array[1]!==null) path = path_array[1];

    if(this.state.component_mounted === false) return null;
    switch(path){
      case 'person':
        console.log('Fetch person');
        let person_id = null;
        if(path_array[2]!==null) person_id = path_array[2];
        return this.fetchPerson(person_id);
        break;
      case 'people':
        console.log('Fetch people');
        return this.fetchPeople();
        break;
      case 'structure':
        console.log('Fetch structure');
        let structure_id = null;
        if(path_array[2]!==null) structure_id = path_array[2];
        return this.fetchStructure(structure_id);
        break;
      case 'structures':
        return this.fetchStructures();
        break;
      default:
        return this.fetchPeople();
    }
  }

  handleLensClick(e){
    let inputSearch = document.getElementById('input_search');
    this.setState({query: inputSearch.value});
    inputSearch.blur();
  }

  handleKeyPress(e){
    if(e.key === 'Enter'){
      this.setState({query: e.target.value})
      document.getElementById('input_search').blur();
    }
  }

  render(){
    // console.log(this.state.query);
    // controllo se il componente è caricato e renderizzo la pagina prevista, altrimenti lascio il loader

    const { loading } = this.state;



    if(loading) { // if your component doesn't have to wait for an async action, remove this block
      return (
          <>
            <Alert color="info">
              <Container className="content-center brand">
                <div className="h3">
                  <img src={require("assets/img/sissa-icon-white.png")}
                       style={{
                         height: '2em'
                       }}
                  /> Loading<Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></div>
              </Container>
            </Alert>
          </>
      )
    }

    return (
        <>
          <IndexEffect />
          <IndexNavbar />
          <div className="wrapper">
            {/*<IndexHeader searchBar={<SearchBar />}/>*/}
            <div className="page-header clear-filter" filter-color="blue">
              <div>
                <div
                    className="page-header-image"
                    style={{
                      // backgroundImage: "linear-gradient(transparent, white 99%), url(" + require("assets/img/sissa-bg-001.jpg") + ")",
                      backgroundImage: "url(" + require("assets/img/sissa-bg-001.jpg") + ")",
                      height: '18em',
                      backgroundColor: "black",
                      backgroundPosition: "left bottom",
                      backgroundBlendMode: "normal",
                    }}
                    ref={this.state.pageHeader}
                ></div>
              </div>
              <Container>
                <div className="content-center brand">
                  <img
                      alt="SISSA Logo"
                      className="n-logo"
                      width="75px"
                      src={require("assets/img/sissa-logo-white-small.png")}
                  ></img>
                  <h1
                      className="h1-seo title-full"
                      style={{
                        lineHeight: "0.67rem"
                      }}
                  >SISSA Phonebook</h1>
                  <h1 className="h1-seo title-mobile"
                      style={{
                        lineHeight: "0.67rem",
                        fontSize: "22px"
                      }}
                  >Phonebook</h1>
                  {/*<h3>A beautiful Bootstrap 4 UI kit. Yours free.</h3>*/}
                  <Row>
                    <Col>
                      <InputGroup className={this.state.rightFocus ? "input-group-focus" : ""} id="input_search_group">
                        <Input
                            placeholder="Make your search"
                            id="input_search"
                            type="search"
                            onFocus={() => this.setState({leftFocus: true, rightFocus: true})}
                            onBlur={() => this.setState({leftFocus: false, rightFocus: false})}
                            onKeyPress={(e) => this.handleKeyPress(e)}
                            // onChange={(e) => this.setState({query: e.target.value})}
                            // onSubmit={(e) => this.setState({query: e.target.value})}
                            // onChange={(e) => this.setState({query: e.target.value})}
                            style={{ backgroundColor: 'white'}}
                        ></Input>
                        <InputGroupAddon
                            addonType="append"
                            onClick={(e) => this.handleLensClick(e)}
                        >
                          <InputGroupText>
                            &nbsp;<i className="now-ui-icons ui-1_zoom-bold"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
            <IndexHeaderEffect />
            <Row><br/></Row>
            <div className="main" style={{textAlign: "center"}}>
              <Col id="res_message" style={{display:"none"}}>Displaying first 20 results, refine your search</Col>
              <Row key={"results_row"}>
              {this.fetchResults()}
              </Row>
            </div>
            <Row><br/><br/><br/><br/><br/></Row>
            <DarkFooter />
          </div>
        </>
    );
  }

}

export default Index;
