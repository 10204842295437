import React, {Component} from "react";

// reactstrap components
import {
    Card,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Col, CardFooter,
} from "reactstrap";
import Obfuscate from 'react-obfuscate';

// core components

class SissaPersonCard extends Component {

    tab_prefix = "structureCardTab_" + this.props.card.id + "_";

    state = {
        iconPills: this.tab_prefix + "1"
    };

    membersList() {
        if (Object.keys(this.props.card.members).length === 0) return '';

        return <div className={"members_list"}>{this.props.card.members.map((item, index) => (
            <><span key={this.props.card.id + "_person_" + index} className="card-item"><b><a
                href={"/person/" + item.person_id}>{item.full_name}</a></b></span><span
                key={this.props.card.id + "_roles_" + index} className="card-item small">{item.role}</span></>
        ))}</div>;
    }

    structure_roles() {
        console.log(this.props.card.roles);
        if (Object.keys(this.props.card.roles).length === 0) return '';
        return <div><span><b>Organizational roles</b></span><br/><br/>{this.props.card.roles.map((item, index) => (
            <span key={index} className="card-item"><a
                href={"/person/" + item.person_id}>{item.full_name}</a><br/><span>{item.type}</span>
            </span>

        ))}
            <hr/>
        </div>;
    }

    structure_mission() {

    }

    structure_parent() {
        console.log(this.props.card.parent);
        if (this.props.card.parent === null) return '';

        let description = this.props.card.parent.acronym;
        if (this.props.card.parent.description_en === "") description = description + " - " + this.props.card.parent.description_it;
        else description = description + " - " + this.props.card.parent.description_en;
        return <div>
            {/*<span>{this.props.card.category_en}</span><br/>*/}
            <span><b>Overlying structure</b></span><br/><br/>
            <span className="card-item"><a
                href={"/structure/" + this.props.card.parent.id}>{description}</a><br/>
                <span>{this.props.card.parent.category_en}</span>
            </span>
            <hr/>
        </div>
    }

    structure_children() {
        console.log(this.props.card.children);
        if (this.props.card.children === null) return '';
        return <div>
            <span><b>Underlying structures</b></span><br/><br/>
            {this.props.card.children.map((item, index) => (
                // const description = item.acronym;
                // if(item.description_en==="") description = description+ " - " + item.description_it;
                // else description = description+ " - " + item.description_en;
                <><a href={"/structure/" + item.id}>{this.child_description(item)}</a><br/><span>{item.category_en}</span>
                    <br/></>
            ))}
        </div>
    }

    child_description(item) {
        let description = item.acronym;
        if (item.description_en === "") description = description + " - " + item.description_it;
        else description = description + " - " + item.description_en;
        return description;
    }

    stringToColour(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }

    render() {
        let avatar_color = this.stringToColour(this.props.card.description_en);
        // console.log(avatar_color);
        const avatar_style = {
            backgroundColor: avatar_color
        };

        return (
            <>
                <Col style={{
                    flexBasis: "33%"
                }}>
                    <Card className={"card-profile"}>
                        <div className="card-avatar">
                            <a href={"/structure/" + this.props.card.id}>
                                <span className="profile-span img-raised"
                                      style={avatar_style}
                                >{this.props.card.acronym}</span>
                                {/*<img className="img img-raised" src={this.props.card.image}/>*/}
                            </a>
                        </div>
                        <CardBody>
                            <TabContent
                                className="text-center"
                                activeTab={this.state.iconPills}
                            >
                                <TabPane tabId={this.tab_prefix + "1"}>
                                    <h6 className="card-category">{this.props.card.role_task}</h6>

                                    <h4 className="card-title">{this.props.card.description_en}</h4>
                                    {(() => {
                                        if (this.props.card.email) {
                                            return (
                                                <span className="card-item">
                                                    <i className="now-ui-icons ui-1_email-85"></i>&nbsp;
                                                    <Obfuscate
                                                        email={this.props.card.email}
                                                    />
                                                </span>
                                            )
                                        }
                                    })()}
                                    {(() => {
                                        if (this.props.card.phone) {
                                            return (
                                                <span className="card-item">
                                                <i className="now-ui-icons tech_mobile"></i>&nbsp;
                                                    <Obfuscate
                                                        tel={this.props.card.phone}
                                                    />
                                            </span>
                                            )
                                        }
                                    })()}
                                    {(() => {
                                        if (this.props.card.address && this.props.card.address !== 'Italy') {
                                            return (
                                                <span className="card-item">
                                                <i className="now-ui-icons location_pin"></i> {this.props.card.address}
                                            </span>
                                            )
                                        }
                                    })()}
                                </TabPane>
                                <TabPane tabId={this.tab_prefix + "2"}>
                                    {this.structure_roles()}
                                    {this.structure_parent()}
                                    {this.structure_children()}
                                </TabPane>
                                <TabPane tabId={this.tab_prefix + "3"}>
                                    {this.membersList()}
                                </TabPane>
                            </TabContent>
                        </CardBody>
                        <CardFooter>
                            <Nav className="justify-content-center" role="tablist" data-background-color="black" tabs>
                                <NavItem key={"structure_main"}>
                                    <NavLink
                                        className={this.state.iconPills === this.tab_prefix + "1" ? "active" : ""}
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({iconPills: this.tab_prefix + "1"})
                                        }}
                                    >
                                        <i className="now-ui-icons business_badge"></i>
                                        Main
                                    </NavLink>
                                </NavItem>
                                <NavItem key={"structure_details"}>
                                    <NavLink
                                        className={this.state.iconPills === this.tab_prefix + "2" ? "active" : ""}
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({iconPills: this.tab_prefix + "2"})
                                        }}
                                    >
                                        <i className="now-ui-icons education_paper"></i>
                                        Details
                                    </NavLink>
                                </NavItem>
                                <NavItem key={"structure_members"}>
                                    <NavLink
                                        className={this.state.iconPills === this.tab_prefix + "3" ? "active" : ""}
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({iconPills: this.tab_prefix + "3"})
                                        }}
                                    >
                                        <i className="now-ui-icons users_circle-08"></i>
                                        Members
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardFooter>
                    </Card>
                </Col>
            </>
        );
    }

}

export default SissaPersonCard;
