import React, {Component} from "react";

// reactstrap components
import {
    Alert,
    Card,
    // CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    // Container,
    // Row,
    Col, CardFooter,
} from "reactstrap";
import Obfuscate from "react-obfuscate";

// core components

class SissaPersonCard extends Component {

    tab_prefix = "personCardTab_" + this.props.card.id + "_";
    state = {
        iconPills: this.tab_prefix + "1",
        initials: this.props.card.first_name.charAt(0) + this.props.card.last_name.charAt(0)
    };

    rolesList() {
        const roles = [];
        if (Object.keys(this.props.card.structure_roles).length === 0) return roles;
        this.props.card.structure_roles.forEach((data, index) => {
            let entry = <>{data.description}<br /><a
                href={"/structure/" + data.structure_id}>{data.structure}</a></>;
            roles.push(<span key={index} className="card-item">{entry}</span>);
        })
        return <><h6 className="card-category">Role</h6>
            <div className="sissa-person-ul">{roles}</div>
            <hr/>
        </>;
    }

    positionsList() {
        const positions = [];
        if (Object.keys(this.props.card.positions).length === 0) return positions;
        this.props.card.positions.forEach((data, index) => {
            positions.push(<span key={index} className="card-item">{data}</span>);
        })
        return <><h6 className="card-category">Position</h6>
            <div className="sissa-person-ul">{positions}</div>
            <hr/>
        </>;
    }

    managers() {
        console.log(this.props.card.managers);
        const managers = [];
        if (Object.keys(this.props.card.managers).length === 0) return managers;
        this.props.card.managers.forEach((element, index) => {
            // let unique_key = this.props.card.id+'_affiliations_'+index;
            managers.push(<><span>{element.description}</span><a key={index} href={"/person/" + element.manager_id}><span
                className="card-item">{element.fullName}</span></a></>);
        })
        return <><hr/><h6 className="card-category">Managers</h6><div className="sissa-person-ul">{managers}</div></>;
    }

    affiliationsList() {
        const affiliations = [];
        if (Object.keys(this.props.card.affiliations).length === 0) return affiliations;
        this.props.card.affiliations.forEach((element, index) => {
            // let unique_key = this.props.card.id+'_affiliations_'+index;
            affiliations.push(<a key={index} href={"/structure/" + element.structure_id}><span
                className="card-item">{element.description}</span></a>);
        })
        return <><h6 className="card-category">Affiliation</h6>
            <div className="sissa-person-ul">{affiliations}</div>
        </>;
    }

    phonesList() {
        if (Object.keys(this.props.card.contacts.phones).length === 0) return '';
        let is_array = Array.isArray(this.props.card.contacts.phones);
        let phones_list = [];
        if (!is_array) {
            for (let phone_key in this.props.card.contacts.phones) {
                phones_list.push(this.props.card.contacts.phones[phone_key]);
            }
        } else {
            phones_list = this.props.card.contacts.phones;
        }
        return <div>{phones_list.map((item, index) => (
            <span key={index} className="card-item"><i className="now-ui-icons tech_mobile"></i>&nbsp;<Obfuscate
                tel={item}/></span>
        ))}
            <hr/>
        </div>;
    }

    emailsList() {
        if (Object.keys(this.props.card.contacts.emails).length === 0) return '';
        return <div>{this.props.card.contacts.emails.map((item, index) => (
            <span key={index} className="card-item"><i className="now-ui-icons ui-1_email-85"></i>&nbsp; <Obfuscate
                email={item}/></span>
        ))}
            <hr/>
        </div>;
    }

    locationList() {
        if (Object.keys(this.props.card.contacts.location).length === 0) return '';
        return <div>{this.props.card.contacts.location.map((item, index) => (
            <span key={index} className="card-item"><i
                className="now-ui-icons location_pin"></i> {item.full_location}</span>
        ))}</div>;
    }

    disclaimerMessage() {
        if (this.props.card.disclaimer === true) {
            return <div><hr/><Alert color="info">
               This user opposes to third-parties use of above personal data,
                    for commercial and marketing and profiling (as per Dlgs 196 of 30/06/2003 and art. 13 GDPR - UE 2016/679).
                </Alert></div>
        } else {
            return '';
        }
    }

    stringToColour(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }

    render() {
        let person_name = null;
        if (this.props.card.full_name === null)
            person_name = this.props.card.first_name + ' ' + this.props.card.last_name;
        else
            person_name = this.props.card.full_name;

        let avatar_color = this.stringToColour(person_name);
        // console.log(avatar_color);
        const avatar_style = {
            backgroundColor: avatar_color
        };


        let image;
        let external_network = this.props.external_network;

        if (!this.props.card.image_url.includes('NoPhoto') && external_network === false) {
            image = <><img className="img img-raised" src={this.props.card.image_url} alt={"Profile image"}/></>
        } else {
            image = <><span className="profile-span img-raised"
                            style={avatar_style}
            >{this.state.initials}</span></>
        }
        return (
            <>
                <Col key={"person_col_" + this.props.card.id} style={{
                    flexBasis: "33%"
                }}>
                    <Card className={"card-profile"}>
                        <div className="card-avatar">
                            <a href={"/person/" + this.props.card.id}
                               onClick={(e) => {
                                   e.preventDefault();
                                   document.location.pathname = 'person/' + this.props.card.id;
                                   // window.history.replaceState(null, '', '/person/'+this.props.card.id);
                                   document.getElementById('search_people').classList.add('active');
                                   document.getElementById('search_structures').classList.remove('active');
                               }}
                            >
                                {image}
                            </a>
                        </div>
                        <CardBody>
                            <TabContent
                                className="text-center"
                                activeTab={this.state.iconPills}
                            >
                                <TabPane tabId={this.tab_prefix + "1"}>
                                    <h6 className="card-category">{this.props.card.role_task}</h6>

                                    <h4 className="card-title">{this.props.card.full_name}</h4>
                                    {(() => {
                                        if (this.props.card.sissa_email) {
                                            return (
                                                <span className="card-item">
                                                    <i className="now-ui-icons ui-1_email-85"></i>&nbsp;
                                                    <Obfuscate
                                                        email={this.props.card.sissa_email}
                                                    />
                                                </span>
                                            )
                                        }
                                    })()}
                                    {(() => {
                                        if (this.props.card.sissa_phone) {
                                            return (
                                                <span className="card-item">
                                                    <i className="now-ui-icons tech_mobile"></i>&nbsp;
                                                    <Obfuscate
                                                        tel={this.props.card.sissa_phone}
                                                    />
                                                </span>
                                            )
                                        }
                                    })()}
                                    {(() => {
                                        if (this.props.card.contacts.location[0]) {
                                            return (
                                                <span className="card-item">
                                                    <i className="now-ui-icons location_pin"></i>&nbsp;
                                                    {this.props.card.contacts.location[0].room_number + ' - ' + this.props.card.contacts.location[0].location}<br/>
                                                    {this.props.card.contacts.location[0].address}
                                                </span>
                                            )
                                        }
                                    })()}
                                    {this.disclaimerMessage()}
                                </TabPane>
                                <TabPane tabId={this.tab_prefix + "2"}>
                                    {this.rolesList()}

                                    {this.positionsList()}

                                    {this.affiliationsList()}

                                    {this.managers()}
                                </TabPane>
                                <TabPane tabId={this.tab_prefix + "3"}>
                                    {this.phonesList()}
                                    {this.emailsList()}
                                    {this.locationList()}
                                    {this.disclaimerMessage()}
                                </TabPane>
                            </TabContent>
                        </CardBody>
                        <CardFooter>
                            <Nav className="justify-content-center" role="tablist" data-background-color="black" tabs>
                                <NavItem key={"person_main"}>
                                    <NavLink
                                        className={this.state.iconPills === this.tab_prefix + "1" ? "active" : ""}
                                        href="#people"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({iconPills: this.tab_prefix + "1"})
                                        }}
                                    >
                                        <i className="now-ui-icons business_badge"></i>
                                        Main
                                    </NavLink>
                                </NavItem>
                                <NavItem key={"person_details"}>
                                    <NavLink
                                        className={this.state.iconPills === this.tab_prefix + "2" ? "active" : ""}
                                        href="#people"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({iconPills: this.tab_prefix + "2"})
                                        }}
                                    >
                                        <i className="now-ui-icons education_paper"></i>
                                        Details
                                    </NavLink>
                                </NavItem>
                                <NavItem key={"person_contacts"}>
                                    <NavLink
                                        className={this.state.iconPills === this.tab_prefix + "3" ? "active" : ""}
                                        href="#people"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({iconPills: this.tab_prefix + "3"})
                                        }}
                                    >
                                        <i className="now-ui-icons ui-1_send"></i>
                                        Contacts
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardFooter>
                    </Card>
                </Col>
            </>
        );
    }

}

export default SissaPersonCard;
