import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    let path_array = document.location.pathname.split("/");
    let pathname = '';
    if(path_array[1]!==null) pathname=path_array[1];

    console.log('nav '+pathname);
    if(pathname=='' || pathname == '#' || (pathname != 'people' && pathname != 'structures' && pathname != 'person' && pathname != 'structure')){
      document.location.pathname = 'people';
      document.getElementById('search_people').classList.add('active');
    }

    if(pathname == 'people' || pathname == 'person'){
      document.getElementById('search_people').classList.add('active');
      document.getElementById('search_structures').classList.remove('active');
    }
    if(pathname == 'structures' || pathname == 'structure'){
      document.getElementById('search_people').classList.remove('active');
      document.getElementById('search_structures').classList.add('active');
    }
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });


  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <Nav navbar className="navbar-left"><NavItem>
                <p style={{
                  textTransform: "uppercase",
                  fontSize: "0.7142em",
                  padding: "0.5rem",
                  lineHeight: "1.625rem",
                  color: "white"
                }}>Search for:</p>
            </NavItem>
              <NavItem
                  id="search_people"
              >
              <NavLink
                  href="people"
                  onClick={(e) => {
                    e.preventDefault();
                    document.location.pathname='people';
                    document.getElementById('search_people').classList.add('active');
                    document.getElementById('search_structures').classList.remove('active');
                  }}
              >
                <i className="now-ui-icons users_circle-08"></i>
                <p>People</p>
              </NavLink>
            </NavItem>
              <NavItem
                  id="search_structures"
              >
                <NavLink
                    href="structures"
                    onClick={(e) => {
                      e.preventDefault();
                      document.location.pathname='structures';
                      document.getElementById('search_people').classList.remove('active');
                      document.getElementById('search_structures').classList.add('active');
                    }}
                >
                  <i className="now-ui-icons business_briefcase-24"></i>
                  <p>Structures</p>
                </NavLink>
              </NavItem>
            </Nav>
            {/*<NavbarBrand*/}
            {/*  href="https://www.itcs.sissa.it"*/}
            {/*  id="navbar-brand"*/}
            {/*>*/}
            {/*  ITCS Website*/}

            {/*</NavbarBrand>*/}

            {/*<UncontrolledTooltip target="#navbar-brand">*/}
            {/*  Managed by Information Technologies and Computing Services*/}
            {/*</UncontrolledTooltip>*/}
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  href="https://www.sissa.it"
                  target="_blank"
                  id="website-tooltip"
                >
                  <i className="fab fa fa-globe"></i>
                  <p className="d-lg-none d-xl-none">Website</p>
                </NavLink>
                <UncontrolledTooltip target="#website-tooltip">
                  Visit our website
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://twitter.com/sissaschool"
                  target="_blank"
                  id="twitter-tooltip"
                >
                  <i className="fab fa-twitter"></i>
                  <p className="d-lg-none d-xl-none">Twitter</p>
                </NavLink>
                <UncontrolledTooltip target="#twitter-tooltip">
                  Follow us on Twitter
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://it-it.facebook.com/SISSAschool"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  Like us on Facebook
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.instagram.com/sissaschool/"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-instagram"></i>
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
                <UncontrolledTooltip target="#instagram-tooltip">
                  Follow us on Instagram
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
